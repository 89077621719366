
$('.read-bio').click(function (e) {
  e.preventDefault();
  $(this).siblings('.bio').toggleClass('active');
  $(this).toggleClass('hide');
});



// page is now ready, initialize the calendar...

$(document).ready(function () {
  if ($('#calendar').length == 0) return;

  $('#calendar').fullCalendar({
    header: {
      left: 'prev,next today',
      center: 'title',
      right: 'month,basicWeek,basicDay'
    },

    navLinks: true, // can click day/week names to navigate views
    editable: true,
    eventLimit: true, // allow "more" link when too many events
    eventSources: [

      {

        url: '/meetings/data',
        type: 'GET',
        dataType: 'json',


      },

    ],
  });
});
$(document).ready(function () {
  initializeMap();
  var meeting_map;
  function initializeMap() {
    if ($('#meeting-map').length == 0) return;

    meeting_map = new google.maps.Map(document.getElementById('meeting-map'), {
      zoom: 2,
      center: new google.maps.LatLng(latitude, longitude),
      mapTypeId: 'roadmap',
      animation: google.maps.Animation.DROP,
      scrollwheel: false,

    });




    var markers = $('#meeting-map').data('url');

    $.ajax(markers)
      .done(function (data) {
        $.each(data, function (itm, marker) {
          if (marker.lat == "0.00000000" || marker.lon == "0.00000000") {
            return false;
          }
          addMapMarker({
            position: new google.maps.LatLng(marker.lat, marker.lon),
            type: 'info',
            animation: google.maps.Animation.DROP,
            title: marker.title,
            date: marker.date,
            venue: marker.venue,
            url: marker.url
          })
        })


      });
  }

  function addMapMarker(feature) {
    var marker = new google.maps.Marker({
      position: feature.position,
      animation: google.maps.Animation.DROP,
      map: meeting_map,
      title: feature.title
    });

    var contentString = '<div class="map-content" id="content">' +
      '<div id="siteNotice"></div>' +
      '<h5 id="firstHeading" class="firstHeading">' + feature.title + '</h5>' +
      '<div id="bodyContent">' +
      '<div class="row"><div class="col-xs-2">' +
      '<span class="fa fa-calendar fa-2x"></span></div><div class="col-xs-10"> ' + feature.date + '<br>' + feature.venue +
      '</div></div>' +
      '<a class="link" style="font-weight:600;font-family:Nunito Sans" href="' + feature.url + '"><span class="fa fa-angle-right"></span> DETAILS</a>' +
      '</div>' +
      '</div>';

    var infowindow = new google.maps.InfoWindow({
      content: contentString
    });
    marker.addListener('click', function () {
      infowindow.open(map, marker);
    });
  }


});


$(function () {
  if ($('.calendar_sc').length == 0) {
    return;
  }

  $('.calendar_sc').each(function (itm, key) {
    console.log('init calendar')
    var $el = $(this);
    var route = $el.data('route');

    var cRef = $el.fullCalendar({
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,basicWeek,basicDay'
      },

      navLinks: true, // can click day/week names to navigate views
      editable: false,
      eventLimit: false, // allow "more" link when too many events
      eventSources: [
        {

          url: route,
          type: 'GET',
          dataType: 'json',

        },
      ],
    });
    $el.data('cref', cRef);
  });

});